export const DEFAULT_AVATAR_IMAGE = "/assets/images/default_nft.png"
export const LOADER= "/assets/images/loader.gif"

export const COIN1_NFT_IMAGE = "/assets/images/Coin1.png"
export const COIN2_NFT_IMAGE = "/assets/images/Coin2.png"

export const ARROW_DOWN_ICON = "/assets/images/icons/arrow_down.svg";
export const ARROW_UP_ICON = "/assets/images/icons/arrow_up.svg";

export const ANCIENT1_IMAGE= "/assets/images/ancient1.png"
export const ANCIENT2_IMAGE= "/assets/images/ancient2.png"

export const INFO_IMAGE= "/assets/images/info.png"
export const INFO2_IMAGE= "/assets/images/info2.png"

export const LOADING = "/assets/images/loading.gif"