import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";

export const CustomNavbar = styled(Navbar)`
  background-color: black !important;
  color: white !important;
`

export const CustomBrand = styled(Navbar.Brand)`
  color: white;
  font-size: 30px;  
  &:hover{
    color: #bbb;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const NavContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-left:10px;
`
export const CustomNav = styled(Nav)`
justify-content: end;
display: flex;
  @media screen and (max-width: 768px) {
    justify-content: end;
    display: flex;
  }
`

export const CustomNavbarCollapse = styled(Navbar.Collapse)`

`

export const CustomToggle = styled(Navbar.Toggle)`
  background-color: white;
`