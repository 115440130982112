

export const MAIN_PROJECT_ID: string = "mainnet0M6XPl9PJ4fnhv6MsVvpnxwya2RpGEzV"


export const COMPANY_ADDRESS: string = "addr1qyh9zj324a8j4uzd8t0wp4akgsa59pe8ex98j44ql3kcvd5x8n87hfmk3nu27q920sp28y0m0g4fvn3pxhc93mp6f78scg8duf"

// /**** for testing */
// export const MAIN_POLICY_ID: string = "ea32676207d0478347d50d01473db64f964237e9912bd8a592eef269";
// export const MAIN_PROJECT_ID: string = "preprodiecpTou8tNNXmfHWpZn4xYod9rcTlWk9"





