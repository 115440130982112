import axios from "axios";
import { MAIN_PROJECT_ID } from "constants/_main.constant";

export async function getMyNFTs(wallet: string) {
    const options = {
        url: "https://4y90ofqzdi.execute-api.eu-west-2.amazonaws.com/default/crashr-getNFTs?address=" + wallet,
    };
    try {
        const response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}



export async function fetchData(wallet: string) {
    const url = `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${wallet}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'project_id': MAIN_PROJECT_ID,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }


  export async function fetchAssets(wallet: string) {
    try {
      const data = await fetchData(wallet); // Pass the wallet to fetchData
      const getAssetId = data.amount || [];
      const assets = getAssetId.filter((item) => item.unit !== "lovelace").map((item) => item.unit);
      const blockfrostAPIUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';
    
  
      const assetRequests = await Promise.all(assets.map(async (asset) => {
        try {
          const response = await axios.get(`${blockfrostAPIUrl}/assets/${asset}`, {
            headers: {
              'Content-Type': 'application/json',
              project_id: MAIN_PROJECT_ID,
            },
          });
          return response.data;
        } catch (error) {
          console.error(`Error fetching NFT data for asset ${asset}:`, error.message);
          return null; // Returning null in case of error, consider how you want to handle these
        }
      }));
  
      return assetRequests.filter(asset => asset !== null); // Filter out nulls from failed requests
    } catch (error) {
      throw new Error(error.message);
    }
  }