import styled from 'styled-components';

interface TypographyProps {
  color?: string;
  textAlign?: string;
  fontWeight?: string | number;
  fontFamily?: string;
  fontSize?: string;
  marginBottom?: string;
}

export const PageTitle = styled.h1<TypographyProps>`
  font-size: ${(props) => props.theme.fontxxxl};
  font-weight: 700;
  margin-bottom: ${(props) => props.marginBottom || '32px'};
  text-align: ${(props) => props.textAlign || 'center'};

  @media (max-width: 489px) {
    font-size: ${(props) => props.theme.fontlx};
  }
`;

export const H2 = styled.h2<TypographyProps>`
  font-size: ${(props) => props.theme.fontxxxl};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin-bottom: 32px;

  @media (max-width: 489px) {
    font-size: ${(props) => props.theme.fontlx};
  }
`;

export const H3 = styled.h3<TypographyProps>`
  font-size: ${(props) => props.fontSize || props.theme.fontxxl};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  text-transform: uppercase;

  @media (max-width: 489px) {
    font-size: 18px;
  }
`;

export const H4 = styled.h4<TypographyProps>`
  font-family: ${(props) => props.fontFamily || 'inherit'};
  font-size: ${(props) => props.fontSize ?? props.theme.fontxlm};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  text-transform: uppercase;

  @media (max-width: 489px) {
    font-size: 1.5em;
  }
`;

export const H5 = styled.h5<TypographyProps>`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  @media (max-width: 489px) {
    font-size: 0.8em;
  }
`;

export const H6 = styled.h6<TypographyProps>`
  font-size: ${(props) => props.fontSize || props.theme.fontlx};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  letter-spacing: 0.05em;
  font-weight: ${(props) => props.fontWeight || 400};

  @media (max-width: 489px) {
    font-size: 10px;
  }
`;

export const P = styled.p<TypographyProps>`
  font-size: ${(props) => props.fontSize || props.theme.fontlg};
  color: ${(props) => props.color || '#fff'};
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 400};

  @media (max-width: 489px) {
    font-size: 0.75em;
  }
`;
