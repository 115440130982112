import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { H2 } from '../common/Typography';
import Spinner from 'react-bootstrap/Spinner';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 9999;

`;

const AppLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2500);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return isLoading ? (
    <Wrapper>
      <H2>Loading</H2>
      <Spinner animation="border" variant="primary" />
    </Wrapper>
  ) : null;
};

export default AppLoader;
