import { Container, Navbar } from 'react-bootstrap';
import { CustomBrand, CustomNav, CustomNavbar } from './index.styled';
import ConnectWalletModal from '../../modal/ConnectWalletModal';
import { useState } from 'react';
import { useWalletConnect } from 'hooks/WalletConnect';
import CustomButton from 'components/common/CustomButton';
import { FlexBox } from 'components/common/FlexBox';

const Navigation = () => {
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [connected, setConnected] = useState<boolean>(false);
  const { myWalletAddress, disableWalletAddress } = useWalletConnect();
  console.log('myWalletAddress', myWalletAddress);


  const disconnectWallet = () => {
    disableWalletAddress();
    window.location.href = '/';
  };
  return (
    <CustomNavbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <img src="/favicon.png" width="50px" height="50px" />
        <CustomBrand href="/">Treasure Trove</CustomBrand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <CustomNav className="me-auto"></CustomNav>
          <CustomNav>
            {myWalletAddress ? (
              <FlexBox justifyContent="end" width="270px" smJustifyContent="end" smWidth="100%">
                <CustomButton
                  text={myWalletAddress.slice(0, 8) + '...'}
                  fontSize="25px"
                  width="150px"
                  height="40px"
                  onClick={() => {}}
                />
                <CustomButton
                  text={`Disconnect`}
                  fontSize="25px"
                  width="150px"
                  height="40px"
                  onClick={() => {
                    disconnectWallet();
                  }}
                />
              </FlexBox>
            ) : (
              <FlexBox justifyContent="end" width="270px" smJustifyContent="end" smWidth="100%">
                <CustomButton
                  text="Connect Wallet"
                  fontSize="25px"
                  width="150px"
                  height="40px"
                  onClick={() => {
                    setShowWalletModal(true);
                  }}
                />
              </FlexBox>
            )}
          </CustomNav>
        </Navbar.Collapse>
      </Container>
      {showWalletModal && (
        <ConnectWalletModal
          show={showWalletModal}
          onClose={() => setShowWalletModal(false)}
          setConnected={setConnected}
        />
      )}
    </CustomNavbar>
  );
};

export default Navigation;
