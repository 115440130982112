import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from './Navigation';

const LayoutStyle = styled.div`
  min-height: 100vh;
`;

const MainLayout = () => {

  return (
    <LayoutStyle>
      <Navigation />
      <Outlet />
    </LayoutStyle>
  );
};

export default MainLayout;
