import { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layouts/main';
import 'index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { useWalletConnect } from 'hooks/WalletConnect';
import { fetchData } from 'api/api';
import axios from 'axios';

const HomePage = lazy(() => import('./pages/Home'));

interface GlobalContextType {
  myNFTs: MyNFT[]; // Update with the correct type.
  setMyNFTs: (nfts: MyNFT[]) => void; // Update with the correct type.
  userData: UserDataType;
  setUserData: (data: UserDataType) => void;
}

export const GlobalProvider = createContext<GlobalContextType>({} as GlobalContextType);

function App() {
  const [myNFTs, setMyNFTs] = useState([]);
  const [userData, setUserData] = useState<UserDataType>({
    username: '',
    avatar: '',
    points: 0,
    bomber_username: '',
    bomber_avatar: '',
    crashr: '',
    twitter: '',
    discord: '',
    wallet: '', // Make sure to include the 'wallet' property.
    friends: []
  });

  const { myWalletAddress, walletConnected } = useWalletConnect();

  const contextValues = {
    myNFTs,
    setMyNFTs,
    userData,
    setUserData
  };

  
  const getMyNFTsData = async () => {
    if (!walletConnected || !myWalletAddress) return;
  
    try {
      const data = await fetchData(myWalletAddress);
      const getAssetId = data.amount || [];
      const assets = getAssetId
        .filter((item) => item.unit !== "lovelace")
        .map((item) => item.unit);
  
      const isValidNFT = (assetUnit) => {
        const assetPrefix = assetUnit.slice(0, 56);
        return (
          assetPrefix === '61ed7ec15440ff62fb62ca486c19aaee1d157caa5e6f62ee4a3f3426' ||
          assetPrefix === '51168488977b6af42b56dc1d6c3c29207d42277013e961053739390d' ||
          assetPrefix === '145c335a59dc79a37cca9170d75502f158b4856df9d2c2d625ded16a'
        
        );
      };
      
      const validAssets = assets.filter(isValidNFT);

      const blockfrostAPIKey = 'mainnet0M6XPl9PJ4fnhv6MsVvpnxwya2RpGEzV';
      const blockfrostAPIUrl = 'https://cardano-mainnet.blockfrost.io/api/v0/';

      let validNFTs = [];

  
      const fetchNFTWithCaching = async (asset) => {
        try {
          const cachedData = sessionStorage.getItem(`NFT_${asset}`);
  
          if (cachedData) {
            validNFTs.push(JSON.parse(cachedData));
          } else {
            const response = await axios.get(`${blockfrostAPIUrl}/assets/${asset}`, {
              headers: {
                'Content-Type': 'application/json',
                project_id: blockfrostAPIKey,
              },
            });
  
            const nftData = response.data;
            sessionStorage.setItem(`NFT_${asset}`, JSON.stringify(nftData));
            validNFTs.push(nftData);
  
            if (response.status === 429) {
              console.log('Rate limit exceeded. Retrying after a delay...');
              await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds
              return fetchNFTWithCaching(asset); // Retry the request
            }
          }
        } catch (error) {
          console.error(`Error fetching NFT data for asset ${asset}:`, error.message);
        }
      };
  
      for (const asset of validAssets) {
        await fetchNFTWithCaching(asset);
      }
  
      console.log("valid", validAssets);
  
      setMyNFTs(validNFTs);
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
  };
  

  useEffect(() => {
    getMyNFTsData();
  }, [myWalletAddress, walletConnected]);

  return (
    <GlobalProvider.Provider value={contextValues}>
      <BrowserRouter>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<HomePage />} />
            </Route>
          </Routes>
          <ToastContainer />
      </BrowserRouter>
    </GlobalProvider.Provider>
  );
}

export default App;




//   const getMyNFTsData = async () => {
//     if (!walletConnected || !myWalletAddress) return; // Exit if wallet is not connected or address is not set

//     try {
//       const data = await fetchData(myWalletAddress);
//       const getAssetId = data.amount || [];
//       const assets = getAssetId
//         .filter((item) => item.unit !== "lovelace")
//         .map((item) => item.unit);

//       const isValidNFT = (assetUnit) => {
//         const assetPrefix = assetUnit.slice(0, 56);
//         return assetPrefix === '137b05c462f40943d52e4b594dfd28d8d86fc208e606e5984ea8be7c';
//       };

//       const validAssets = assets.filter(isValidNFT);

//       let validNFTs = [];

//       const fetchNFTWithCaching = async (asset) => {
//         const cachedData = sessionStorage.getItem(`NFT_${asset}`);

//         if (cachedData) {
//           validNFTs.push(JSON.parse(cachedData));
//         } else {
//           const response = await axios.get(`${blockfrostAPIUrl}/assets/${asset}`, {
//             headers: {
//               'Content-Type': 'application/json',
//               project_id: blockfrostAPIKey,
//             },
//           });

//           if (response.status === 200) {
//             const nftData = response.data;
//             sessionStorage.setItem(`NFT_${asset}`, JSON.stringify(nftData));
//             validNFTs.push(nftData);
//           } else if (response.status === 429) {
//             console.log('Rate limit exceeded. Retrying after a delay...');
//             await new Promise(resolve => setTimeout(resolve, 5000));
//             return fetchNFTWithCaching(asset);
//           }
//         }
//       };

//       await Promise.all(validAssets.map(asset => fetchNFTWithCaching(asset)));

//       setMyNFTs(validNFTs);
//     } catch (error) {
//       console.error('Error fetching NFTs:', error);
//     }
//   };

//   useEffect(() => {
//     getMyNFTsData();
//   }, [myWalletAddress, walletConnected]);

//   return (
//     <div>
//       {/* Render your NFTs here */}
//     </div>
//   );
// };

// export default MyNFTsComponent;
