import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { light } from './styles/Themes';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WalletConnectProvider } from './hooks/WalletConnect';
import { GlobalStyles } from 'styles/GlobalStyles';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={light}>
      <>
        <GlobalStyles />
        <WalletConnectProvider>
          <App />
        </WalletConnectProvider>
      </>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
